import React from "react";
export default ({ color, background }) => (
  <div
    className="loader-wrapper"
    style={{ background: background ? background : "transparent" }}
  >
    <div className="loader-content">
      <svg
        className="loader-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 72 72"
        width="72"
        height="72"
      >
        <ellipse
          fill={color ? color : "#fff"}
          cx="36"
          cy="9.15"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="54.49"
          cy="17.18"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="62.87"
          cy="36.08"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="54.49"
          cy="54.99"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="36"
          cy="62.85"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="17.51"
          cy="54.97"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="9.13"
          cy="36.07"
          rx="5.13"
          ry="5.15"
        />

        <ellipse
          fill={color ? color : "#fff"}
          cx="17.51"
          cy="17.18"
          rx="5.13"
          ry="5.15"
        />
      </svg>

      <h2 style={{ color: color ? color : "#fff" }}>Loading...</h2>
    </div>
  </div>
);
