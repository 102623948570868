import React from "react";
import styledComponents from "./style";
import paymentReceived from "../../images/Payment-Received.svg"

const  { Container, ConfirmationTitle, ConfirmationMessage } = styledComponents
export default function PaymentSuccess ({ translations }) {
    return (
        <Container>
            <img src={paymentReceived}  />
            <ConfirmationTitle>{translations.paymentReceived.title}</ConfirmationTitle>
            <ConfirmationMessage>{translations.paymentReceived.description}</ConfirmationMessage>
        </Container>
    );
}