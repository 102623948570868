import React from 'react';

import "./Fonts.css";
import "./App.css";
import 'semantic-ui-css/semantic.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import NotFound from "./pages/NotFound";

export default class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <GlobalStyle /> */}
        <BrowserRouter>
          <Routes>
              <Route path='/view/:id' element={<PaymentPage />} />
              <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }
};
