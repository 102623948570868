import React, { PureComponent } from "react";
import Loading from "./Loading";

class Loader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.isLoading ? <Loading color={this.props.color} /> : this.props.children;
  }
}

export default Loader;
