import React from "react";
import styles from "./styles";

const Message = props => {
  const { Content, ContentMobile } = styles;

  return props.isMobile ? (
    <ContentMobile background={props.background}>
      <div>{props.children}</div>
    </ContentMobile>
  ) : (
    <Content background={props.background}>
      <div>{props.children}</div>
    </Content>
  );
};

export default Message;
