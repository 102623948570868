import { useState, useEffect } from "react";

import React from "react";
import Message from "../../components/message";
import Ghost from "../../images/ghostie.svg";

const NotFound = () => {
  const [translations, setTranslations] = useState(null);

  useEffect(() => {
    let parameters = {
      method: "GET",
      headers: {
        'accept-language': navigator.language
      }
    };

    fetch('/translations', parameters)
    .then(response => {
      return response.json();
    })
    .then(paymentJson => setTranslations(paymentJson.translations))
    .catch(error => {
      console.log('error', error);
    });
  }, []);

  return (
    <Message>
      <img style={{ marginBottom: "1rem" }} alt="Page Not Found" src={Ghost} />
      <h1 style={{ color: "#3E4148" }}>{translations?.pageNotFound.header}</h1>
      <p style={{ color: "#3E4148" }}>
        {translations?.pageNotFound.description}
      </p>
    </Message>
  );
};

export default NotFound;