import React, { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";

export default function CheckoutForm({ clientSecret }) {
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe || !clientSecret) return;

    const loadCheckout = async () => {
      const checkout = await stripe.initEmbeddedCheckout({'clientSecret': clientSecret});
      checkout.mount('#checkout');
    };
    
    loadCheckout().catch(console.error);
  }, [stripe]);

  return (<div id="checkout" style={{margin: '10px 0'}}></div>);
}