
import React from "react"
import styledComponents from "./style";
import Grimace from "../../images/Grimace@3x.png";
import QmeritLogo from "../../images/Qmerit-Wordmark-IG400.svg"

/*
Refactored from TierUpdateFail component to display simple error message
*/

const {Container, ThanksForUpdate ,ThanksForUpdateText, Footer} = styledComponents;
const PaymentError = ({ translations }) => {
    return (
        <Container>
            <img alt="Oops" style={{height: 110}} src={Grimace} />
            <ThanksForUpdate>{translations.paymentError.messageOne}&hellip;</ThanksForUpdate>
            <ThanksForUpdateText>{translations.paymentError.messageTwo}{" "}
                <a href="mailto:customerservice@qmerit.com">
                    customerservice@qmerit.com
                </a>
            </ThanksForUpdateText>
            <Footer src={QmeritLogo}/>
        </Container>
    );
};

export default PaymentError;