import styled, { css, createGlobalStyle } from 'styled-components';
import {
  mediaMax,
  mediaMin
} from "../../shared/style";

const GlobalTrackerStyles = createGlobalStyle`
  /* Prevents layout shift when overflow: hidden is set in modals */
  html {
    scrollbar-gutter: stable;
    scroll-behavior: smooth;
  }

  body {
    background: #ffffff !important;
  }

  #root {
    padding: 0px !important;
    color: #3E4148;
  }
`;

const MainWrapper = styled.div`
/* common */
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&family=Red+Hat+Display:wght@400;500;700&display=swap');

  & p, a, li, span, td, th, button {
    font-family: Mulish, sans-serif;
  }

  & h1, h2, h3 {
    font-family: "Red Hat Display", sans-serif;
  }

  height: max-content;
  background-color: white;
  padding: 88px 0px 0px;
  width: 100%;

  ${mediaMax.desktop`
   padding: 52px 0px 111px;
`};
`;

const Header = styled.div`
  /* common */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  border-bottom: 1px solid;
  border-color: #E1E3E9;
  gap: 8px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  height: 64px;
  width: 100%;

  background: #ffffff;

  /* Mobile header */
  ${mediaMax.desktop`
    justify-content: flex-end;
    height: 48px;
    padding: 12px 16px;
    position: absolute;
  `};
`;

const Page = styled.div`
/* common */
  padding-left: 15%;
  padding-right: 15%;
  width: 100%;
  position: fixed;
  top: 64px;
  z-index: 1;
  height: calc(100% - 64px);
  overflow-y: scroll;

  /* Mobile */
  ${mediaMax.desktop`
    width: 100%;
    top: 48px;
    padding-left: 0;
    padding-right: 0;
    left: 0;
    height: calc(100% - 48px);
  `};
`;

const H1 = styled.h1`
/* common */
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 0px;

`;

const P = styled.p`
/* common */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;

`;


const H2 = styled.h2`
/* common */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;

`;


const P_MicroBold = styled.p`
/* common */
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;

`;

const P_Micro = styled.p`
/* common */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;

`;

const P_SM = styled.p`
/* common */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

`;

const ViewAllButton = styled.button`
/* common */

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 160px;
  height: 32px;
  border: 2px solid #3E4148;
  border-radius: 4px;
  background: white;

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: #3963FE;

  cursor: pointer;

  &:hover {
    background: #3963fe;
    color: white;
    border: 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  ${mediaMax.desktop`
    display: none;
  `};

`;

const ViewAllMobileButton = styled.button`

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 100%;
  height: 40px;

  border: 2px solid #3E4148;
  border-radius: 4px;

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: #3963FE;
  background: #FFFFFF;

  &:active {
    background: #3963FE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    border: 0px;
  }

  ${mediaMin.desktop`
    display: none;
  `};

`;


const Label_Field = styled.p`
/* common */

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;

  ${props =>
    props.tablet &&
    css`
      text-align: center;
    `};

  ${mediaMax.phone`
    text-align: left;
  `};
`;


const P_Overflow = styled.p`
/* common */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;

`;

const P_SM_Overflow = styled.p`

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6E727C;
  margin: 4px 0px 0px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;

`;


const Link = styled.a`

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;

  /* link / new */
  color: #3963FE;

`;

const ChevronRight = styled.i`

  height: 20px;
  width: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
`;


const LearnMoreButton = styled.button`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 160px;
  height: 32px;

  background: #FFFFFF;
  border-radius: 4px;
  border: none; 

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;

  text-transform: uppercase;

  color: #3963FE;

`;

const P_Grayed = styled.p`

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;

  color: #6E727C;

`;

const P_RightAligned = styled.p`

/* common */

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  margin: 0px;

  color: #3E4148;

/*   ${mediaMax.phone`
    font-size: 14px;
  `}; */

`;


const P_Bold = styled.p`

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3E4148;
  margin: 0px;

`;


const P_Lrg_Bold = styled.p`

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #3E4148;
  margin: 0px;

`;

const Link_Gray = styled.button`

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  text-align: center;
  text-transform: uppercase;

  /* text / secondary */
  color: #6E727C;
  background: white;
  border: none;
  cursor: pointer;
  padding: 0px;

`;

const CancelIcon = styled.i`

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex !Important;
  align-items: center;
  text-align: center;

  /* indiqogrey/500 - #969BA5 */
  color: #969BA5;

`;

const QmeritLogo = styled.img`
  width: 108.11px;
  height: 32px;

  ${mediaMax.desktop`
    width: 81.08px;
    height: 24px;
  `};
`;

const H4 = styled.h4`
/* common */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 0px;

`;

const P_Contact = styled.a`
/* common */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  align-items: center;
  margin: 0px;

  /* ig/ig-40 */
  color: #3963FE;
`;

export default {
  MainWrapper,
  Header,
  H1,
  P,
  H2,
  P_MicroBold,
  P_Micro,
  P_SM,
  ViewAllButton,
  ViewAllMobileButton,
  Label_Field,
  P_Overflow,
  P_SM_Overflow,
  Link,
  ChevronRight,
  LearnMoreButton,
  P_Grayed,
  P_RightAligned,
  P_Bold,
  P_Lrg_Bold,
  Link_Gray,
  CancelIcon,
  QmeritLogo,
  H4,
  P_Contact,
  Page,
  GlobalTrackerStyles
}