import styled, { css } from "styled-components";

const Container = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-left: 15px;
    padding-right: 15px;

  @media (min-width: 426px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ConfirmationTitle = styled.h2`
  color: rgb(62, 65, 72);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  text-align: center;

  @media (min-width: 426px) {
    width: 400px;
  }
`;

const ConfirmationMessage = styled.p`
  color: rgb(62, 65, 72);
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  height: 60px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: left;


  @media (min-width: 426px) {
    width: 400px;
  }
`;

export default {
    Container,
    ConfirmationTitle,
    ConfirmationMessage,
}