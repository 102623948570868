import styled, { css } from "styled-components";
export const theme = {
  Green: "#33691e",
  GreenBg: "rgba(220, 237, 200, 0.95)",
  Action: {
    "100": "#b2ebf2",
    "700": "#0097a7",
    "900": "#006064",
    "400": "#00e5ff"
  },

  White: "#ffffff",
  Black: "#000000",
  Primary: {
    main: "#00bcd4",
    mainDark: "#00acc1",
    dark: "#3862c5",
    extraDark: "#2b4dac",
    "100": "#c3cfee",
    "900": "#172d87"
  },
  IndiqoGrey: {
    main: "#fafafa",
    "100": "#f4f5f6",
    "200": "#edeeef",
    "300": "#dedfe2",
    "400": "#b9bbc1",
    "500": "#969ba5",
    "600": "#6e727c",
    "800": "#3e4148"
  },
  Success: {
    "500": "#8bc34a"
  },
  CoolGrey: "#b9bbc1",
  OrangeyRed: { "100": "#ff5722", "200": "#f4511e" },
  Warning: {
    "100": "#ffccbc",
    "500": "#ffc107",
    "700": "#ffa000",
    "900": "#bf360c"
  },
  // OrangeyRed: "#ff5722",
  Success: { "500": "#8bc34a", "600": "#689f38" },
  DisabledGreen: { "100": "#dcedc8", "200": "#c5e1a5" }
};
export const headerSizes = {
  desktop: 56,
  tablet: 56,
  phone: 96,
  iphone: 96,
  iphoneOld: 84
};
export const sizes = {
  phone: 576.98,
  tablet: 767.98,
  desktop: 991.98
};
export const mediaMax = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const mediaMin = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const fade = (hex, opacity) => {
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
};
export const text = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  color: ${theme.IndiqoGrey[800]};
`;
export const mediumText = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${theme.IndiqoGrey[600]};
`;
export const smallText = css`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 16px;
  color: ${theme.IndiqoGrey[600]};
`;

export const primaryButton = css`
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #ffffff;
  border-radius: 100px;
  border: solid 1px #00acc1;
  min-height: 32px;
  padding: 4px 16px;

  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(${theme.Primary.main}),
    to(${theme.Primary.mainDark})
  );
  background-image: -webkit-linear-gradient(
    top,
    ${theme.Primary.main},
    ${theme.Primary.mainDark}
  );
  background-image: -o-linear-gradient(
    top,
    ${theme.Primary.main},
    ${theme.Primary.mainDark}
  );
  background-image: linear-gradient(
    to bottom,
    ${theme.Primary.main},
    ${theme.Primary.mainDark}
  );
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(${theme.Primary.mainDark}),
      to(${theme.Primary.main})
    );
    background-image: -webkit-linear-gradient(
      top,
      ${theme.Primary.mainDark},
      ${theme.Primary.main}
    );
    background-image: -o-linear-gradient(
      top,
      ${theme.Primary.mainDark},
      ${theme.Primary.main}
    );
    background-image: linear-gradient(
      to bottom,
      ${theme.Primary.mainDark},
      ${theme.Primary.main}
    );
  }
`;

export const Doc = styled.div`
font-size: 12px;
left: 0;
top: 0;
width: 100% !important;
height: 100% !important;
display: flex;
justify-content: center;
flex-direction: column;
text-align: center;
position: absolute;
box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
i {
  font-size: 20px;
  text-align: center;
}
${props =>
  (props.type === "microDoc" || props.type === "microPdf") &&
  `background-color: #e0f7fa;
color: #00bcd4;`}
${props =>
  props.type === "microExcel" &&
  `background-color: #f1f8e9;
  color: #8bc34a;`}
${props =>
  props.type === "microPower" &&
  `background-color: #fff8e1;
    color: #ffa000;`}
/* ${props =>
  props.type === "microPdf" &&
  `  background-color: #e0f7fa;
          color: #00bcd4;;
      `}; */
`;

export const QuoteBoundingBox = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: #fafafa;
  width: 100%;
  padding-top: 100%;
  display: block;
  position: relative;
`;

export const QuoteImages = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 3px 0 ${fade(theme.Black, 0.2)},
    0 2px 2px 0 ${fade(theme.Black, 0.12)}, 0 0 2px 0 ${fade(theme.Black, 0.14)};
`;

export const UploadFileWrapper = styled.div`
  margin-top: 4px;
`;
export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ErrorIcon = styled.span`
  margin: 2.55px;
  color: #ff5722;
  font-size: 12px;
`;
export const Error = styled.div`
  color: #ff5722;
  font-size: 12px;
  font-style: italic;
  margin-top: 2.55px;
`;

export const cardShadow = css`
  box-shadow: 0px 2px 4px 0px ${fade(theme.Black, 0.14)},
    0px 4px 5px 0px ${fade(theme.Black, 0.12)},
    0px 1px 10px 0px ${fade(theme.Black, 0.2)};
`;

export const cardLightShadow = css`
  box-shadow: 0px 0px 2px 0px ${fade(theme.Black, 0.14)},
    0px 2px 2px 0px ${fade(theme.Black, 0.12)},
    0px 1px 3px 0px ${fade(theme.Black, 0.2)};
`;

export const PaddinMarginBits = css`
  ${({ pt }) =>
    pt &&
    css`
      padding-top: ${pt}px !important;
    `};
  ${({ pr }) =>
    pr &&
    css`
      padding-right: ${pr}px !important;
    `};
  ${({ pb }) =>
    pb &&
    css`
      padding-bottom: ${pb}px !important;
    `};
  ${({ pl }) =>
    pl &&
    css`
      padding-left: ${pl}px !important;
    `};
  ${({ px }) =>
    px &&
    css`
      padding-left: ${px}px !important;
      padding-right: ${px}px !important;
    `};
  ${({ py }) =>
    py &&
    css`
      padding-top: ${py}px !important;
      padding-bottom: ${py}px !important;
    `};
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt}px !important;
    `};
  ${({ mr }) =>
    mr &&
    css`
      margin-right: ${mr}px !important;
    `};
  ${({ mrSm }) =>
    mrSm &&
    css`
      ${mediaMin.phone`margin-right: ${mrSm}px !important;`};
    `};
  ${({ mrMd }) =>
    mrMd &&
    css`
      ${mediaMin.tablet`margin-right: ${mrMd}px !important;`};
    `};
  ${({ mrLg }) =>
    mrLg &&
    css`
      ${mediaMin.desktop`margin-right: ${mrLg}px !important;`};
    `};
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px !important;
    `};
  ${({ mbSm }) =>
    mbSm &&
    css`
      ${mediaMin.phone`margin-bottom: ${mbSm}px !important;`};
    `};
  ${({ mbMd }) =>
    mbMd &&
    css`
      ${mediaMin.tablet`margin-bottom: ${mbMd}px !important;`};
    `};
  ${({ mbLg }) =>
    mbLg &&
    css`
      ${mediaMin.desktop`margin-bottom: ${mbLg}px !important;`};
    `};
  ${({ ml }) =>
    ml &&
    css`
      margin-left: ${ml}px !important;
    `};
  ${({ mlSm }) =>
    mlSm &&
    css`
      ${mediaMin.phone`margin-left: ${mlSm}px !important;`};
    `};
  ${({ mlMd }) =>
    mlMd &&
    css`
      ${mediaMin.tablet`margin-left: ${mlMd}px !important;`};
    `};
  ${({ mlLg }) =>
    mlLg &&
    css`
      ${mediaMin.desktop`margin-left: ${mlLg}px !important;`};
    `};
  ${({ mx }) =>
    mx &&
    css`
      margin-left: ${mx === "auto" ? "auto !important" : `${mx}px !important`};
      margin-right: ${mx === "auto" ? "auto !important" : `${mx}px !important`};
    `};
  ${({ mxSm }) =>
    mxSm &&
    css`
      ${mediaMin.phone`
        margin-left: ${
          mxSm === "auto" ? "auto !important" : `${mxSm}px !important`
        };
        margin-right: ${
          mxSm === "auto" ? "auto !important" : `${mxSm}px !important`
        };
      `};
    `};
  ${({ mxMd }) =>
    mxMd &&
    css`
      ${mediaMin.tablet`
        margin-left: ${
          mxMd === "auto" ? "auto !important" : `${mxMd}px !important`
        };
        margin-right: ${
          mxMd === "auto" ? "auto !important" : `${mxMd}px !important`
        };
      `};
    `};
  ${({ mxLg }) =>
    mxLg &&
    css`
      ${mediaMin.desktop`
        margin-left: ${
          mxLg === "auto" ? "auto !important" : `${mxLg}px !important`
        };
        margin-right: ${
          mxLg === "auto" ? "auto !important" : `${mxLg}px !important`
        };
      `};
    `};
  ${({ my }) =>
    my &&
    css`
      margin-top: ${my}px !important;
      margin-bottom: ${my}px !important;
    `};
  ${({ mySm }) =>
    mySm &&
    css`
      ${mediaMin.phone`margin-top: ${mySm}px !important;
      margin-bottom: ${mySm}px !important;`};
    `};
  ${({ myMd }) =>
    myMd &&
    css`
      ${mediaMin.tablet`margin-top: ${myMd}px !important;
      margin-bottom: ${myMd}px !important;`};
    `};
  ${({ myLg }) =>
    myLg &&
    css`
      ${mediaMin.desktop`margin-top: ${myLg}px !important;
      margin-bottom: ${myLg}px !important;`};
    `};
`;
const floatingCode = css`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.14),
      0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  }
  &:focus {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14),
      0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  }
  &.disabled {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
      0px 3px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;
const commonBtnStyle = css`
  font-family: Lato;
  border-radius: 100px;
  font-weight: bold;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  text-transform: uppercase;
  width: 100%;

  ${({ floating }) =>
    floating &&
    css`
      ${floatingCode};
    `};
  ${({ pFloating }) =>
    pFloating &&
    mediaMax.tablet`${css`
      ${floatingCode};
    `}`};
  ${({ dFloating }) =>
    dFloating &&
    mediaMin.tablet`${css`
      ${floatingCode};
    `}`};

  max-width: ${({ fWidth }) => (fWidth ? "100%" : "200px")};
  ${({ mdWidth }) => mdWidth && mediaMin.tablet`max-width: ${mdWidth}px`};}
  ${({ dBlock }) =>
    dBlock &&
    css`
      display: block;
    `};

  ${({ xl }) =>
    xl &&
    css`
      font-size: 24px;
      height: 72px;
      letter-spacing: 1.5px;
      line-height: 32px;
    `};
  ${({ lg }) =>
    lg &&
    css`
      font-size: 16px;
      height: 52px;
      letter-spacing: 1px;
      line-height: 20px;
    `};
  ${({ sm }) =>
    sm &&
    css`
      font-size: 12px;
      height: 32px;
      letter-spacing: 1px;
      line-height: 16px;
    `};
  ${({ xl, lg, sm }) =>
    !xl &&
    !lg &&
    !sm &&
    css`
      font-size: 12px;
      height: 40px;
      letter-spacing: 1px;
      line-height: 16px;
    `};
`;
const defaultBtn = css`
  color: rgb(110, 114, 124);
  border-color: #edeeef;
  background: linear-gradient(-180deg, #f4f5f6 0%, #edeeef 100%) #edeeef;
  &:hover {
    border-color: #dedfe2;
    background: linear-gradient(-180deg, #f4f5f6 0%, #dedfe2 100%) #dedfe2;
  }
  &:focus {
    border-color: #edeeef;
    background: #dedfe2;
  }
  &.disabled {
    color: #dedfe2;
    border-color: #edeeef;
    background: #f4f5f6;
  }
`;
const primaryBtn = css`
  color: #ffffff;
  border-color: #3359ba;
  background: linear-gradient(-180deg, #3862c5 0%, #3359ba 100%) #3359ba;
  &:hover {
    border-color: #2b4dac;
    background: linear-gradient(-180deg, #3862c5 0%, #2b4dac 100%) #2b4dac;
  }
  &:focus {
    border-color: #edeeef;
    background: #2b4dac;
  }
  &.disabled {
    color: #9bb1e2;
    border-color: #edeeef;
    background: #c3cfee;
  }
`;
const actionBtn = css`
  color: #ffffff;
  border-color: #00acc1;
  background: linear-gradient(-180deg, #00bcd4 0%, #00acc1 100%) #00acc1;
  &:hover {
    border-color: #0097a7;
    background: linear-gradient(-180deg, #00bcd4 0%, #0097a7 100%) #0097a7;
  }
  &:focus {
    border-color: #edeeef;
    background: #0097a7;
  }
  &.disabled {
    color: #80deea;
    border-color: #edeeef;
    background: #b2ebf2;
  }
`;
const successBtn = css`
  color: #ffffff;
  border-color: #7cb342;
  background: linear-gradient(-180deg, #8bc34a 0%, #7cb342 100%) #7cb342;
  &:hover {
    border-color: #689f38;
    background: linear-gradient(-180deg, #8bc34a 0%, #689f38 100%) #689f38;
  }
  &:focus {
    border-color: #edeeef;
    background: #689f38;
  }
  &.disabled {
    color: #c5e1a5;
    border-color: #edeeef;
    background: #dcedc8;
  }
`;
const alertBtn = css`
  color: #ffffff;
  border-color: #ffb300;
  background: linear-gradient(-180deg, #ffc107 0%, #ffb300 100%) #ffb300;
  &:hover {
    border-color: #ffa000;
    background: linear-gradient(-180deg, #ffc107 0%, #ffa000 100%) #ffa000;
  }
  &:focus {
    border-color: #edeeef;
    background: #ffa000;
  }
  &.disabled {
    color: #ffd54f;
    border-color: #edeeef;
    background: #ffecb3;
  }
`;
const dangerBtn = css`
  color: #ffffff;
  border-color: #f4511e;
  background: linear-gradient(-180deg, #ff5722 0%, #f4511e 100%) #f4511e;
  &:hover {
    border-color: #e64a19;
    background: linear-gradient(-180deg, #ff5722 0%, #e64a19 100%) #e64a19;
  }
  &:focus {
    border-color: #edeeef;
    background: #e64a19;
  }
  &.disabled {
    color: #ffab91;
    border-color: #edeeef;
    background: #ffccbc;
    cursor: default;
  }
`;
export const buttonStyle = css`
  ${PaddinMarginBits};
  ${commonBtnStyle};
  ${({ primary }) => primary && `${primaryBtn};`};
  ${({ action }) => action && `${actionBtn};`};
  ${({ success }) => success && `${successBtn};`};
  ${({ alert }) => alert && `${alertBtn};`};
  ${({ danger }) => danger && `${dangerBtn};`};
  ${({ primary, action, success, alert, danger }) =>
    !primary && !action && !success && !alert && !danger && `${defaultBtn}`};
`;
export const Button = styled.button`
  ${buttonStyle};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
  th {
    text-align: left;
    height: 32px;
    color: ${theme.IndiqoGrey[800]};
    font-weight: bold;
    box-shadow: inset 0px -1px 0px 0px rgb(222, 223, 226);
    i {
      color: #aeb2ba;
      ${({ up }) =>
        up &&
        css`
          transform: scaleY(-1);
          transition: 0.1s;
        `};
    }
  }
  td {
    height: 48px;
  }
  tr {
    &.active {
      td {
        background: linear-gradient(
          -180deg,
          rgb(0, 188, 212) 0%,
          rgb(0, 172, 193) 100%
        ) !important;
        color: #fff;
        i {
          color: #fff;
        }
      }
    }

    &:not(:last-child) {
      td {
        box-shadow: inset 0px -1px 0px 0px rgb(237, 238, 239);
      }
    }
    &:nth-child(odd) {
      td {
        background: ${theme.IndiqoGrey.main};
      }
    }
  }
`;
